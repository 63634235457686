'use strict';
/* global Vimeo */
/**
 * What does this function do? "SIDE SCROLLING"
 * @param {*} wrapperElement what is this parameter?
 * @param {*} element what is this parameter?
 * @param {*} scrollArrowRight what is this parameter?
 * @param {*} scrollArrowLeft what is this parameter?
 * @param {*} isResults what is this parameter?
 */
function addScrollingWithVelocity(wrapperElement, element, scrollArrowRight, scrollArrowLeft, isResults) {
    let pv = {};
    pv.wrapperElement = wrapperElement;
    pv.element = element;
    pv.currentXpos = 0;
    pv.lastXpos = pv.currentXpos;
    pv.direction = 'for';
    pv.x;
    pv.y;
    pv.friction = 0.96;
    pv.newX = 0;
    pv.oldX = 0;
    pv.xSpeed;
    pv.getSpeed = false;
    pv.pressed = false;
    pv.vThrottle = 20;// throttle for getting swipe speed - samples 10 times a second
    pv.vThrottleInc = 0;
    pv.maxNumber = 0;
    pv.xScrollSpeed = false;
    pv.results = isResults;
    pv.scrollDistance = element.offsetWidth-pv.wrapperElement.offsetWidth;
    pv.clickthrough = true;
    pv.currentLoc = 0;
    pv.lastLoc = 0;
    pv.startPointX;
    pv.startPointY;
    pv.differenceX;
    pv.differenceY;
    pv.e = undefined;
    pv.position = function() {
        element.style.left = '0px';
        pv.lastXpos = 0;
        pv.currentXpos = 0;
    };

    window.addEventListener('resize', pv.position);

    if (isTouchDevice) {
        scrollArrowRight.addEventListener('touchstart', function(e) {
            pv.touchleft(element);
        });
        scrollArrowRight.addEventListener('touchend', function(e) {
            pv.touchendleft(element);
        });
        scrollArrowLeft.addEventListener('touchstart', function(e) {
            pv.touchright(element);
        });
        scrollArrowLeft.addEventListener('touchend', function(e) {
            pv.touchendright(element);
        });
    } else {
        scrollArrowRight.addEventListener('mousedown', function(e) {
            pv.moveleft(element);
        });
        scrollArrowRight.addEventListener('mouseup', function(e) {
            pv.stopleft(element);
        });
        scrollArrowRight.addEventListener('mouseleave', function(e) {
            pv.stopleft(element);
        });
        scrollArrowLeft.addEventListener('mousedown', function(e) {
            pv.moveright(element);
        });
        scrollArrowLeft.addEventListener('mouseup', function(e) {
            pv.stopright(element);
        });
        scrollArrowLeft.addEventListener('mouseleave', function(e) {
            pv.stopright(element);
        });
    }

    /**
     * What does this function do?
     * @param {*} pv what is this parameter?
     */
    function positionBgImgs(pv) {
        // let rowWidth = (pv.element.offsetWidth-pv.scrollDistance);
        let rowX = -pv.wrapperElement.scrollLeft;
        let inc = 0;
        for (let v in pv.element.childNodes) {
            if (pv.element.childNodes[v].firstChild !== undefined) {
                let rowImg = pv.element.childNodes[v].querySelector('.rowItemImg');
                if (rowImg===null) {
                    rowImg = pv.element.childNodes[v].querySelector('.rowSelectsItemImg');
                }
                if ((rowImg!==null)) {
                    if ((pv.element.id === 'rowSelectsInnerId')&&(mobileSize)) {
                        // let rowSelectsItem = pv.element.childNodes[v];
                        // positionMobileSelects(rowSelectsItem, v);
                    } else {
                        let itemX = (mobileSize?140:220)*inc;// videoThumb.offsetLeft; - hard coded because offsetLeft initally returns 0
                        let itemWidth = (mobileSize?120:200);// videoThumb.offsetWidth; - same deal
                        let viewRange = window.innerWidth;
                        let itemPos = (rowX+itemX+itemWidth)-(itemWidth/2);
                        if (itemPos < 0-(itemWidth/2)) {
                            if (rowImg.offsetLeft != 0) {
                                rowImg.style.left = 0+'px';
                            }
                        } else if (itemPos > viewRange+(itemWidth/2)) {
                            if (rowImg.offsetLeft != (mobileSize?-20:-40)) {
                                rowImg.style.left = (mobileSize?-20:-40)+'px';
                            }
                        } else {
                            rowImg.style.left = ((mobileSize?-20:-40)/viewRange*itemPos)+'px';
                        }
                        inc++;
                    }
                }
            }
        }
    }

    positionBgImgs(pv);

    window.addEventListener('resize', function() {
        positionBgImgs(pv);
    });

    pv.pressImg = function(e) {
        // e.preventDefault();
        if (element.offsetWidth > wrapperElement.offsetWidth) {
            pv.x = e.clientX;
            element.addEventListener('mousemove', pv.dragImg);
            element.addEventListener('mouseleave', pv.releaseImg);
            pv.startPointX = pv.x;
            pv.differenceX = pv.x;
            pv.xSpeed = 0;
            pv.getSpeed = true;
            window.requestAnimationFrame(pv.getVelocitySpeed);
        }
    };

    pv.moveImg = function(x) {
        pv.scrollDistance = element.offsetWidth-wrapperElement.offsetWidth;
        pv.x = x;
        pv.differenceX = (pv.x-pv.startPointX);
        pv.currentXpos = pv.lastXpos+pv.differenceX;
        let distance = pv.differenceX;// measure how far user moves to detemine whether its a swipe or a click
        // let forwardDir = false;
        if (distance < 0) {
            distance = -distance;
            // forwardDir = true;
        }
        if (distance > 10) {
            pv.clickthrough = false;
            if (pv.currentXpos > 0) {
                pv.currentXpos = 0;
            } else if (pv.currentXpos < -pv.scrollDistance) {
                pv.currentXpos = -pv.scrollDistance;
            }
            pv.moveThings();
        } else {
            pv.clickthrough = true;
        }
    };
    pv.swipeImg = function(e) {
        // e.preventDefault();
        // pv.moveImg(e.touches[0].clientX);
    };

    pv.dragImg = function(e) {
        // e.preventDefault();
        pv.moveImg(e.clientX);
    };

    pv.releaseImg = function(e) {
        // e.preventDefault();
        if (isTouchDevice) {
            // element.removeEventListener('touchmove', pv.swipeImg);
        } else {
            element.removeEventListener('mousemove', pv.dragImg);
            element.removeEventListener('mouseleave', pv.releaseImg);
        }
        pv.getSpeed = false;

        isClickThrough = pv.clickthrough;
        if (!pv.clickthrough) {
            pv.lastXpos = pv.currentXpos;
            window.requestAnimationFrame(pv.velocityScroll);
        }
    };

    if (isTouchDevice) {
        // element.addEventListener('touchstart', pv.pressImg);
        // element.addEventListener('touchend', pv.releaseImg);
    } else {
        element.addEventListener('mousedown', pv.pressImg);
        element.addEventListener('mouseup', pv.releaseImg);
    }

    pv.getVelocitySpeed = function() {
        if (pv.vThrottleInc>=pv.vThrottle) {
            pv.newX = pv.x;
            pv.xSpeed = (pv.newX - pv.oldX)/5;// fine tune the velocity with a multiplier
            pv.oldX = pv.newX;
            pv.vThrottleInc = 0;
        }
        if (pv.getSpeed) {
            window.requestAnimationFrame(pv.getVelocitySpeed);
            pv.vThrottleInc++;
        }
    };

    pv.buttonPress = function() {
        pv.scrollDistance = element.offsetWidth-wrapperElement.offsetWidth;
        pv.currentXpos += pv.xSpeed;
        if (pv.currentXpos > 0) {
            pv.currentXpos = 0;
        } else if (pv.currentXpos < -pv.scrollDistance) {
            pv.currentXpos = -pv.scrollDistance;
        }

        pv.moveThings();
        pv.lastXpos = pv.currentXpos;

        if (pv.pressed) {
            window.requestAnimationFrame(pv.buttonPress);
        }
    };

    pv.velocityScroll = function() {
        pv.scrollDistance = element.offsetWidth-wrapperElement.offsetWidth;
        pv.currentXpos += pv.xSpeed;

        if (pv.currentXpos > 0) {
            pv.currentXpos = 0;
        } else if (pv.currentXpos < -pv.scrollDistance) {
            pv.currentXpos = -pv.scrollDistance;
        }

        if ((pv.element.id === 'rowSelectsInnerId')&&(mobileSize)) {
            // console.log('velocityScroll '+pv.currentXpos);
            /*
            let forwardDir = pv.lastXpos>pv.currentXpos;
            if (forwardDir) {
                pv.xSpeed = -40;
            } else {
                pv.xSpeed = 40;
            }
            */
        } else {
            // Everything Else, add Friction
            pv.xSpeed *= pv.friction;
        }
        pv.moveThings();
        if (pv.xSpeed > 0.001 || pv.xSpeed < -0.001) {
            window.requestAnimationFrame(pv.velocityScroll);
        } else {
            pv.xSpeed = 0;
        }
        pv.lastXpos = pv.currentXpos;
        // console.log('velocity '+pv.currentXpos);
    };

    pv.moveThings = function() {
        let scrollArrowL = 'scrollArrowLeft';
        let scrollArrowR = 'scrollArrowRight';

        if (!pv.results) {
            scrollArrowL = 'scrollSelectsArrowLeft';
            scrollArrowR = 'scrollSelectsArrowRight';
        }
        if (pv.currentXpos === 0) {
            pv.currentXpos = 0;
            scrollArrowLeft.className = scrollArrowL+' scrollActive';
            scrollArrowRight.className = scrollArrowR+'  scrollInactive';
        } else if (pv.currentXpos === -pv.scrollDistance) {
            pv.currentXpos = -pv.scrollDistance;
            scrollArrowLeft.className = scrollArrowL+' scrollInactive';
            scrollArrowRight.className = scrollArrowR+'  scrollActive';
        } else {
            scrollArrowLeft.className = scrollArrowL+' scrollActive';
            scrollArrowRight.className = scrollArrowR+'  scrollActive';
        }
        // pv.element.style.left = pv.currentXpos+'px';
        pv.wrapperElement.scrollLeft = -pv.currentXpos;
        positionBgImgs(pv);
    };

    pv.moveleft = function(e) {
        pv.xSpeed = 15;
        pv.pressed = true;
        window.requestAnimationFrame(pv.buttonPress);
    };

    pv.stopleft = function(e) {
        pv.xSpeed = 0;
        pv.pressed = false;
    };

    pv.touchleft = function(e) {
        pv.xSpeed = 15;
        pv.pressed = true;
        window.requestAnimationFrame(pv.buttonPress);
    };

    pv.touchendleft = function(e) {
        pv.xSpeed = 0;
        pv.pressed = false;
    };

    pv.moveright = function(e) {
        pv.xSpeed = -15;
        pv.pressed = true;
        window.requestAnimationFrame(pv.buttonPress);
    };

    pv.stopright = function(e) {
        pv.xSpeed = 0;
        pv.pressed = false;
    };

    pv.touchright = function(e) {
        pv.xSpeed = -15;
        pv.pressed = true;
        window.requestAnimationFrame(pv.buttonPress);
    };

    pv.touchendright = function(e) {
        pv.xSpeed = 0;
        pv.pressed = false;
    };
}

/**
 * What does this function do?
 */
function addToCart() {
    let id = currIdOfSelectedVideo;
    rowloop:
    for (let r in pArray) {
        if (pArray.hasOwnProperty(r)) {
            for (let s in pArray[r].page) {
                if (pArray[r].page.hasOwnProperty(s)) {
                    let data = pArray[r].page[s];
                    if (data.vimeoid===id) {
                        data.inCart = true;
                        cartData[id] = {'data': data};
                        break rowloop;
                    }
                }
            }
        }
    }

    if (!playerOnly) {
        setCartIcons();
    }
}

/**
 * What does this function do?
 */
function addToPlaylist() {
    addToCart();
    hideShowMobileHeaderButton();
}

/**
 * What does this function do?
 */
function buildCart() {
    // let currURL = window.location.host;
    let tmp = [];
    let str = '';

    for (let i in cartData) {
        if (cartData.hasOwnProperty(i)) {
            tmp.push(parseInt(cartData[i].data.vimeoid).toString(16)); // convert vimeo ID to hex
        }
    }

    if (tmp.length > 0) {
        str += '?playlist='+escape(tmp.toString());
    }

    if (str.length > 0) {
        window.history.pushState(str, 'urlstring', str);
    } else {
        window.history.pushState(null, null, '/');
    }

    emptyCart();

    cartInner = document.createElement('div');
    cartContent.appendChild(cartInner);
    cartInner.id = 'cartInner';

    for (let i in cartData) {
        if (cartData.hasOwnProperty(i)) {
            let cartRow = document.createElement('div');
            cartInner.appendChild(cartRow);
            cartRow.className = 'cartRow';

            let cartImg = document.createElement('div');
            cartRow.appendChild(cartImg);
            cartImg.className = 'cartImg';
            cartImg.style.backgroundImage = 'url('+cartData[i].data.thumbnailPath+')';
            let cartItemText = document.createElement('div');
            cartItemText.className = 'cartItemText';
            cartRow.appendChild(cartItemText);

            let str = cartData[i].data.brand.split('Z_').join('');
            if (str !== undefined) {
                // str = str.toUpperCase();
                cartItemText.innerHTML = truncate(str, 30); // cap at 60 charaters
            }

            let cartItemTitle = document.createElement('div');
            cartItemTitle.className = 'cartItemTitle';
            cartRow.appendChild(cartItemTitle);

            let tstr = cartData[i].data.description;
            if (tstr !== undefined) {
                // tstr = tstr.toUpperCase();
                cartItemTitle.innerHTML = truncate(tstr, 40); // cap at 60 charaters
            }

            let cartItemCategory = document.createElement('div');
            cartItemCategory.className = 'cartItemCategory';
            cartRow.appendChild(cartItemCategory);

            let cat = cartData[i].data.maincategory;
            if (cat !== undefined) {
                // cat = cat.toUpperCase();
                cartItemCategory.innerHTML = truncate(cat, 60); // cap at 60 charaters
            }

            let cartDelete = document.createElement('div');
            cartRow.appendChild(cartDelete);
            cartDelete.className = 'cartDelete';
            cartDelete.innerHTML = '-';
            if (isTouchDevice) {
                cartDelete.addEventListener('touchstart', function(e) {
                    currIdOfSelectedVideo = i;
                    deleteFromCart();
                });
            } else {
                cartDelete.addEventListener('click', function(e) {
                    currIdOfSelectedVideo = i;
                    deleteFromCart();
                });
            }
        }
        // cart here
    }
}

/**
 * What does this function do?
 * @param {*} isResult what is this parameter?
 * @param {*} e what is this parameter?
 * @param {*} i what is this parameter?
 * @param {*} j what is this parameter?
 */
function buildItem(isResult, e, i, j) {
    let rowItem = e;
    let currArray = resultsArray;
    if (!isResult) {
        currArray = pArray;
    }

    let rowItemInner = document.createElement('div');
    rowItemInner.id = 'rowItemInner'+i;
    rowItem.appendChild(rowItemInner);
    let rowItemText = document.createElement('div');
    rowItemInner.appendChild(rowItemText);
    let str = currArray[i].pageTitle.split('Z_').join('');// currArray[i].page[j].title;
    if (str !== undefined) {
        // str = str.toUpperCase();
        rowItemText.innerHTML = truncate(str, 30); // cap at 60 charaters
    }

    let rowItemDuration = document.createElement('div');
    rowItemInner.appendChild(rowItemDuration);
    let d = currArray[i].page[j].duration;
    let minutes = Math.floor(d / 60);
    let seconds = d - minutes * 60;
    if (seconds < 10) {
        seconds = '0'+seconds;
    }
    if (isNaN(seconds)) {
        rowItemDuration.innerHTML = '';
    } else {
        rowItemDuration.innerHTML = minutes+':'+seconds;
    }
    let rowItemTitle = document.createElement('div');

    rowItemInner.appendChild(rowItemTitle);

    let tstr = currArray[i].page[j].title;
    if (tstr !== undefined) {
        // tstr = tstr.toUpperCase();
        rowItemTitle.innerHTML = truncate(tstr, 60); // cap at 60 charaters
    }

    let rowItemCategory = document.createElement('div');
    rowItemInner.appendChild(rowItemCategory);

    let cat = currArray[i].page[j].maincategory;
    if (cat !== undefined) {
        // cat = cat.toUpperCase();
        rowItemCategory.innerHTML = truncate(cat, 60); // cap at 60 charaters
    }

    let rowItemAddCart = document.createElement('div');
    // rowItemAddCart.className = 'rowItemAddCart plusgold';
    rowItem.appendChild(rowItemAddCart);

    if (isResult) {
        rowItemInner.className = 'rowItemInner';
        rowItemText.className = 'rowItemText';
        rowItemDuration.className = 'rowItemDuration';
        rowItemTitle.className = 'rowItemTitle';
        rowItemCategory.className = 'rowItemCategory';
        if (currArray[i].page[j].inCart===false) {
            rowItemAddCart.className = 'rowItemAddCart plusgold';
        }
        if (currArray[i].page[j].inCart===true) {
            rowItemAddCart.className = 'rowItemAddCart minusgold';
        }
    } else {
        rowItemInner.className = 'rowItemSelectsInner';
        rowItemText.className = 'rowItemSelectsText';
        rowItemDuration.className = 'rowItemSelectsDuration';
        rowItemTitle.className = 'rowItemSelectsTitle';
        rowItemCategory.className = 'rowItemSelectsCategory';
        if (currArray[i].page[j].inCart===false) {
            rowItemAddCart.className = 'rowItemSelectsAddCart plusSelectsgold';
        }
        if (currArray[i].page[j].inCart===true) {
            rowItemAddCart.className = 'rowItemSelectsAddCart minusSelectsgold';
        }
    }
    /*
    if (isTouchDevice) {
        rowItemAddCart.addEventListener('touchstart', function(e) {
            preAddToPlaylist();
        });

        rowItemAddCart.addEventListener('touchend', function(e) {
            currIdOfSelectedVideo = e.target.parentNode.id;
            if ((e.target.className.indexOf('plusgold')!==-1)||(e.target.className.indexOf('plusSelectsgold')!==-1)) {
                addToPlaylist();
            } else {
                deleteFromCart();
            }
        });
    } else {
        rowItemAddCart.addEventListener('mousedown', function(e) {
            preAddToPlaylist();
        });

        rowItemAddCart.addEventListener('mouseup', function(e) {
            currIdOfSelectedVideo = e.target.parentNode.id;
            if ((e.target.className.indexOf('plusgold')!==-1)||(e.target.className.indexOf('plusSelectsgold')!==-1)) {
                addToPlaylist();
            } else {
                deleteFromCart();
            }
        });
    }
    */
    rowItemAddCart.addEventListener('click', function(e) {
        currIdOfSelectedVideo = e.target.parentNode.id;
        if ((e.target.className.indexOf('plusgold')!==-1)||(e.target.className.indexOf('plusSelectsgold')!==-1)) {
            addToPlaylist();
        } else {
            deleteFromCart();
        }
    });
    rowItemInner.addEventListener('click', function(e) {
        if (isClickThrough) {
            openVideoPlayer(e.target.parentNode.id, isResult);
        }
    });
}

/**
 * What does this function do?
 * @param {*} isResult what is this parameter?
 * @param {*} e what is this parameter?
 * @param {*} i what is this parameter?
 * @return {*} what does this return?
 */
function buildRow(isResult, e, i) {
    let rowObj = {};// pass all items into an object for future reference

    let wrapper = e;

    rowObj.projectRow = document.createElement('div');
    wrapper.appendChild(rowObj.projectRow);

    rowObj.rowHead = document.createElement('div');
    rowObj.projectRow.appendChild(rowObj.rowHead);

    rowObj.rowHeadTitle = document.createElement('div');
    rowObj.rowHead.appendChild(rowObj.rowHeadTitle);
    if (!isResult) {
        rowObj.rowBgBody = document.createElement('div');
        rowObj.projectRow.appendChild(rowObj.rowBgBody);

        rowObj.rowBgInner = document.createElement('div');
        rowObj.rowBgBody.appendChild(rowObj.rowBgInner);
    }
    rowObj.rowBody = document.createElement('div');
    rowObj.projectRow.appendChild(rowObj.rowBody);

    rowObj.rowInner = document.createElement('div');
    rowObj.rowBody.appendChild(rowObj.rowInner);
    rowObj.rowInner.setAttribute('isScrolling', false);

    rowObj.scrollArrowRight = document.createElement('div');
    rowObj.projectRow.appendChild(rowObj.scrollArrowRight);

    rowObj.scrollArrowLeft = document.createElement('div');
    rowObj.projectRow.appendChild(rowObj.scrollArrowLeft);

    if (isResult) {
        rowObj.rowHead.className = 'rowHead';
        rowObj.rowBody.className = 'rowBody';
        rowObj.projectRow.className = 'row noselection';
        rowObj.rowHeadTitle.className = 'rowHeadTitle';
        rowObj.rowInner.className = 'rowInner';
        rowObj.rowInner.id = 'rowInner'+i;
        rowObj.scrollArrowRight.className = 'scrollArrowRight';
        rowObj.scrollArrowLeft.className = 'scrollArrowLeft';
    } else {
        rowObj.rowHead.className = 'rowSelectsHead';
        rowObj.rowBody.className = 'rowSelectsBody';
        rowObj.projectRow.className = 'selectrow noselection';
        rowObj.rowHeadTitle.id = 'rowHeadSelectsTitle';
        rowObj.rowBgBody.id = 'rowBgBody';
        rowObj.rowBgInner.id = 'rowBgInner';
        rowObj.rowInner.className = 'rowSelectsInner';
        rowObj.rowInner.id = 'rowSelectsInnerId';
        rowObj.scrollArrowRight.className = 'scrollSelectsArrowRight';
        rowObj.scrollArrowLeft.className = 'scrollSelectsArrowLeft';
    }

    return rowObj;
}

/**
 * What does this function do?
 * @param {*} e what is this parameter?
 * @param {*} i what is this parameter?
 */
function buildShowHideButton(e, i) {
    let rowInner = e;

    let rowItem = document.createElement('div');
    rowItem.className = 'row-show-hide black';
    rowInner.appendChild(rowItem);
    rowItem.id = 'rowShowHide'+i;

    let rowItemText = document.createElement('div');
    rowItemText.className = 'row-show-hide-text';
    rowItem.appendChild(rowItemText);

    rowItemText.innerHTML = 'MORE EXTENDED<br />ASSETS';

    if (isTouchDevice) {
        rowItem.addEventListener('touchstart', function(e) {
            if (isClickThrough) {
                toggleHiddenItems(i, rowItem, rowItemText);
            }
        });
    } else {
        rowItem.addEventListener('click', function(e) {
            if (isClickThrough) {
                toggleHiddenItems(i, rowItem, rowItemText);
            }
        });
    }
}

/**
 * What does this function do?
 */
function centerVideoText() {
    let iframe = document.getElementById('videoEmbed').getElementsByTagName('iframe')[0];
    if (mobileSize) {
        console.log('set aspectratio mobile');
    } else {
        videoPlayerText.style.width = (iframe.offsetHeight*1.7775862)+'px';
        videoPlayerHide.style.width = (iframe.offsetHeight*1.7775862)+'px';
    }
}

/**
 * What does this function do?
 */
function checkForOverlap() {
    let rows = Array.from(content.querySelectorAll('.row'));

    rows.forEach(div => {
        let row = div;

        let rowBody = div.querySelector('.rowBody');
        let rowInner = div.querySelector('.rowInner');

        let scrollArrowRight = div.querySelector('.scrollArrowRight');
        let scrollArrowLeft = div.querySelector('.scrollArrowLeft');

        scrollArrowRight.className = 'scrollArrowRight scrollInactive';
        scrollArrowLeft.className = 'scrollArrowLeft scrollInactive';

        if (rowInner.offsetWidth > row.offsetWidth) {
            let diff = row.offsetWidth-rowInner.offsetWidth;
            let pos = -rowBody.scrollLeft;

            if (pos === 0) {
                scrollArrowLeft.className = 'scrollArrowLeft scrollActive';
            } else if (pos === diff) {
                scrollArrowRight.className = 'scrollArrowRight scrollActive';
            } else {
                scrollArrowLeft.className = 'scrollArrowLeft scrollActive';
                scrollArrowRight.className = 'scrollArrowRight scrollActive';
            }
        }
    });


    let srows = Array.from(featuredContent.querySelectorAll('.selectrow'));

    srows.forEach(div => {
        let row = div;

        let rowSelectsBody = div.querySelector('.rowSelectsBody');
        let rowSelectsInner = div.querySelector('.rowSelectsInner');

        let scrollSelectsArrowRight = div.querySelector('.scrollSelectsArrowRight');
        let scrollSelectsArrowLeft = div.querySelector('.scrollSelectsArrowLeft');

        scrollSelectsArrowRight.className = 'scrollSelectsArrowRight scrollInactive';
        scrollSelectsArrowLeft.className = 'scrollSelectsArrowLeft scrollInactive';

        if (rowSelectsInner.offsetWidth > row.offsetWidth) {
            let diff = row.offsetWidth-rowSelectsInner.offsetWidth;
            let pos = -rowSelectsBody.scrollLeft;

            if (pos === 0) {
                scrollSelectsArrowLeft.className = 'scrollSelectsArrowLeft scrollActive';
            } else if (pos === diff) {
                scrollSelectsArrowRight.className = 'scrollSelectsArrowRight scrollActive';
            } else {
                scrollSelectsArrowLeft.className = 'scrollSelectsArrowLeft scrollActive';
                scrollSelectsArrowRight.className = 'scrollSelectsArrowRight scrollActive';
            }
        }
    });
}

/**
 * What does this function do? "URL STRING PARSE"
 */
function checkForURLString() {
    urlArray = [];

    let query = window.location.search.substring(1);

    if (query.length > 0 && query.indexOf('playlist=')!==-1) {
        let queryCut = query;

        if (query.indexOf('inplayer=')!==-1) {
            let split = query.split('&');
            queryCut = split[0];
            playerOnly = true;
        }

        let cut = queryCut.split('=');
        let arr = unescape(cut[1]);// unescape the array
        urlArray = arr.split(',');

        for (let i in urlArray) {// change all IDs from HEX to Base10
            if (urlArray.hasOwnProperty(i)) {
                urlArray[i] = parseInt(urlArray[i], 16);
                currIdOfSelectedVideo = urlArray[i].toString();
                addToCart();
            }
        }
    }
}

/**
 * What does this function do?
 * @param {*} data what is this parameter?
 * @return {*} what does this return?
 */
/*
function checkUrlArray(data) {
    for (let i in urlArray) {
        if (urlArray[i] == data) {
            return true;
        }
    }
    return false;
}
*/
/**
 * What does this function do?
 */
function checkWidth() {
    if (window.innerWidth > 1024) {
        mobileSize = false;
    } else {
        mobileSize = true;
    }
/*
   isTouchDevice = (function() {
        let check = false;
        (function(a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) {
                check = true;
            }
        })(navigator.userAgent||navigator.vendor||window.opera);
        return check;
    })();
    */
}
/**
 * What does this function do?
 */
function checkRotation() {
    if (mobileSize) {
        if (window.innerHeight > window.innerWidth) {
            videoPlayer.className = 'video-player portrait';
        } else {
            videoPlayer.className = 'video-player landscape';
        }
    }
}
/**
 * What does this function do?
 */
function closeAfterTimeout() {
    delayedCall(1, function() {
        if (!menuover) {
            closeEverythingElse('');
        }
    });
}

/**
 * What does this function do?
 * @param {*} s what is this parameter?
 */
function closeEverythingElse(s) {
    let menu2disp = window.getComputedStyle(menu2panel, null).getPropertyValue('display');
    if (menu2disp === 'block') {
        menu2panel.style.display = 'none';
    }

    let menu3disp = window.getComputedStyle(menu3panel, null).getPropertyValue('display');
    if (menu3disp === 'block') {
        menu3panel.style.display = 'none';
    }
    let cartHamburgerMenuDisp = window.getComputedStyle(cartHamburgerMenu, null).getPropertyValue('display');
    if (cartHamburgerMenuDisp === 'block') {
        cartHamburgerMenu.style.display = 'none';
        cartMenuOpen = false;
    }
    if (menu2YOpen) {
        menu2YOpen = false;
        mobileMenu2panel.style.top = '100vh';
    }
    if (menu3YOpen) {
        menu3YOpen = false;
        mobileMenu3panel.style.top = '100vh';
    }
    if (s.length > 0) {
        search.value = s.split('Z_').join('');
    }
    checkForOverlap();
}

/**
 * What does this function do?
 */
function closeOverlay() {
    videoPlayerOverlay.style.top = '-100vh';
    player.play();
}

/**
 * What does this function do?
 */
function closeSelects() {
    headerArrow.style.transform = 'rotate(-180deg)';
    header.className = 'collapsingHeaderClose';
    videoContent.classList.remove('videoContentOpen');
    videoContent.classList.add('videoContentClose');
    projectsWrapper.className = 'projectsWrapperClose';
    headerInner.className = 'headerInnerClose';
    featuredContent.className = 'featuredContentClose';
    menuBar.className = 'menuBarClose';
    menuBarInner.className = 'menuBarInnerClose';
    cartField.classList.remove('cartFieldOpen');
    cartField.classList.add('cartFieldClose');
    selectsTrayOpen = false;
}

/**
 * What does this function do?
 */
function closeVideoPlayer() {
    videoPlayer.style.opacity = '0';

    delayedCall(1, function() {
        videoPlayer.style.left = '100vw';
    });

    player.pause();
    player.unload();
}

/**
 * What does this function do?
 * @param {*} t what is this parameter?
 * @param {*} func what is this parameter?
 */
function delayed(t, func) {
    window.requestAnimationFrame(countTimer);
    let delayObj = {};
    delayObj.steps = t*60;
    delayObj.inc = 0;

    /**
     * What does this function do?
     */
    function countTimer() {
        delayObj.inc++;
        if (delayObj.inc < delayObj.steps) {
            window.requestAnimationFrame(countTimer);
        } else {
            func();
        }
    }
}

/**
 * What does this function do?
 * @param {*} t what is this parameter?
 * @param {*} f what is this parameter?
 */
function delayedCall(t, f) {
    delayed(t, f);
}

/**
 * What does this function do?
 */
function deleteFromCart() {
    let id = currIdOfSelectedVideo;
    delete cartData[id];
    delloop: for (let r in pArray) {
        if (pArray.hasOwnProperty(r)) {
            for (let s in pArray[r].page) {
                if (pArray[r].page.hasOwnProperty(s)) {
                    let data = pArray[r].page[s];
                    if (data.vimeoid===id) {
                        data.inCart = false;
                        break delloop;
                    }
                }
            }
        }
    }
    setCartIcons();
}

/**
 * What does this function do?
 */
function emptyCart() {
    if ((cartInner!==undefined)) {
        cartContent.removeChild(cartInner);
    }
}

/**
 * What does this function do?
 * @param {*} s what is this parameter?
 * @param {*} t what is this parameter?
 */
function findItemsWith(s, t) {
    resetResults();

    content.removeChild(contentWrapper);

    let temp= [];
    let str = s.toLowerCase();

    for (let i in resultsArray) {// through the pages
        if (resultsArray.hasOwnProperty(i)) {
            let hit = false;
            let itemTemp = [];
            for (let j in resultsArray[i].page) {// through page items
                if (resultsArray[i].page.hasOwnProperty(j)) {
                    for (let k in resultsArray[i].page[j]) {// through page item columns
                        if (resultsArray[i].page[j].hasOwnProperty(k)) {
                            if (setColumns(k)) {
                                let dataItem = resultsArray[i].page[j][k].toString().toLowerCase();
                                if (t==='exact') {
                                    if (dataItem===str) {
                                        hit = true;
                                        itemTemp.push(resultsArray[i].page[j]);
                                    }
                                } else if (t==='partial') {
                                    // if(dataItem.indexOf(str)!==-1){
                                    if ((dataItem.indexOf(' '+str)!==-1) || (dataItem.indexOf(str)===0)) {
                                        hit = true;
                                        itemTemp.push(resultsArray[i].page[j]);
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (hit) {
                const pageTemp = {page: itemTemp, pageTitle: resultsArray[i].pageTitle.split('Z_').join('')};
                temp.push(pageTemp);
            }
        }
    }

    resultsArray = temp;
    sortArray(resultsArray, 'pageTitle', false);
    setResults();
}

/**
 * track Flickity
 * @param {Number} n Number
 */
function flickityMoving(n) {
    let slider = flickityWrapper.querySelector('.flickity-slider');
    let bgslider = gid('rowBgInner');
    let flickLeft = Math.round(n);
    flickityCurrLoc = -Math.round(flickLeft/window.innerWidth);

    if (flickityCurrLoc != flickityLastLoc) {
        console.log(bgslider.childNodes[flickityCurrLoc]+' '+n);
        bgslider.childNodes[flickityCurrLoc].style.opacity = 1;
        if (flickityLastLoc!==undefined) {
            bgslider.childNodes[flickityLastLoc].style.opacity = 0;
        }
    }
    flickityLastLoc = flickityCurrLoc;
    // gid('rowBgInner').style.transform = 'translateX('+(flickLeft)+'px)';
    for (let v in slider.childNodes) {// positions and fades mobile selects images and elements
        if (slider.childNodes[v]!==null) {
            let rowSelectsItem = slider.childNodes[v];
            positionMobileSelects(rowSelectsItem, v);
        }
    }
}

/**
 * Convenient document.getElementById delegate
 * @param {String} s element id
 * @return {DOMNode} node with id 's' or null if dne
 */
function gid(s) {
    return document.getElementById(s);
}

/**
 * What does this function do?
 */
function hideShowMobileHeaderButton() {
    let cartLength = Object.keys(cartData).length;
    if (cartLength < 1) {
        headerMobileToggle.style.display = 'none';
    } else {
        headerMobileToggle.style.display = 'block';
    }
}

/**
 * What does this function do?
 */
function init() {
    counter = 0;
    checkWidth();
    injectLocalData();// number each
    setLinearData();// list out every video item and serialize it by vimeo ID
    resetResults();// set up the initial results array which mirrors the data source
    setSelectsRow();// set of select videos
    setResults();// set up sorted results
    checkForURLString();// see if there's a url string to populate cart
    hideShowMobileHeaderButton();// determine if cart has items. show mobile toggle button if so
    primeVideoplayer(pArray[0].page[0].vimeoid);
    if (playerOnly) {
        setPlaylistArray();
        selectsTabOn = false;
        // videoPlayer.style.cssText += '-webkit-transition: opacity 0s; -moz-transition: opacity 0s; -o-transition: opacity 0s; transition: opacity 0s; opacity:0;';
        directoryBody.style.backgroundColor = '#000000';
        videoplayerBack.style.display = 'none';
        videoplayerBack2.style.display = 'none';
        videoHeaderShare.style.display = 'none';
        videoHeaderPlus.style.display = 'none';
        videoHeaderVim.style.display = 'none';
        openVideoPlayer(playListArray[0].vimeoid, false);
    } else {
        header.style.display = 'block';
        projectsWrapper.style.display = 'block';
        setCategories();// set up menu for categories
        setBrands();// set up menu for brands
        checkForOverlap();// determine if row extends past page width. activate scrolling is yes
    }
    checkRotation();
    setEvents();// set event listeners for the various generated elements

    search.addEventListener('focus', function() {
        if (search.value === 'SEARCH') {
            search.value = '';
        }
        // searching = true;
    });

    search.addEventListener('blur', function() {
        if (search.value === '') {
            search.value = 'SEARCH';
        }
        // searching = false;
    });

    search.addEventListener('keyup', searchSuggestion);
    search.addEventListener('input', function() {
        if (search.value === '') {
            resetResults();
            content.removeChild(contentWrapper);
            sortArray(resultsArray, 'pageTitle', false);
            setResults();
        }
    });
    console.log('init Flick');
    initFlickity();
}

/**
 * What does this function do?
 */
function injectLocalData() {
    sortArray(pArray, 'pageTitle', false);// sort each datasheet tab alphabetically by title

    pArray.forEach((section, sectionNumber) => {
        let pTitle = section.pageTitle;
        section.page.forEach(entry => {
            entry.sectionNumber = sectionNumber;
            entry.inCart = false;
            entry.brand = pTitle;
        });
    });
}

/**
 * What does this function do?
 */
function initFlickity() {
    flickityObj = new Flickity(flickityWrapper, {
    // options
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        pageDots: true,
    });
    flickityLastLoc = undefined;
    flickityMoving(0);
    flickityObj.on('scroll', function(progress) {
        // window.scrollTo(0, 0);
    });
    insetDots();
}

/**
 * What does this function do?
 */
function insetDots() {
    let flickityPageDots = featuredContent.querySelector('.flickity-page-dots');
    let flickityImgWrapper = featuredContent.querySelector('.flickityImgWrapper');
    let dotWidth = 24;
    let dotLength = flickityPageDots.childNodes.length;
    let halfDots = Math.ceil(dotLength/2);
    let Imgbottom = flickityImgWrapper.offsetTop+(flickityImgWrapper.offsetWidth/240*150);// get scaled image height
    flickityPageDots.style.top = (Imgbottom+20)+'px';
    // flickityPageDots.style.bottom = (flickityPageDots.offsetTop+flickityPageDots.offsetHeight)+'px';
    if (dotLength > Math.floor(window.innerWidth/dotWidth)) {
        flickityPageDots.style.width = (halfDots*dotWidth)+'px';
        flickityPageDots.style.height= '36px';
    } else {
        flickityPageDots.style.width = (dotLength*dotWidth)+'px';
        flickityPageDots.style.height= '18px';
    }
}

/**
 * What does this function do?
 * @param {*} b what is this paramter?
 */
function menu1go(b) {
    resetResults();
    content.removeChild(contentWrapper);
    sortArray(resultsArray, 'pageTitle', b);
    setResults();
    closeEverythingElse('SEARCH');
}

/**
 * What does this function do?
 */
function menu2go() {
    if (mobileSize) {
        if (menu2YOpen) {
            menu2YOpen = false;
            mobileMenu2panel.style.top = '100vh';
        } else {
            closeEverythingElse('');
            menu2YOpen = true;
            mobileMenu2panel.style.top = '55vh';// set open top to the opposite of the menu height
        }
    } else {
        let menu2disp = window.getComputedStyle(menu2panel, null).getPropertyValue('display');
        if (menu2disp === 'none') {
            closeEverythingElse('');
            menu2panel.style.display = 'block';
        } else {
            menu2panel.style.display = 'none';
        }
        menuover = true;
    }
}

/**
 * What does this function do?
 * @param {*} e what is this parameter?
 */
function menu3go(e) {
    if (mobileSize) {
        if (menu3YOpen) {
            menu3YOpen = false;
            mobileMenu3panel.style.top = '100vh';
        } else {
            closeEverythingElse('');
            menu3YOpen = true;
            mobileMenu3panel.style.top = '55vh';// set open top to the opposite of the menu height
        }
    } else {
        let menu3disp = window.getComputedStyle(menu3panel, null).getPropertyValue('display');
        if (menu3disp === 'none') {
            closeEverythingElse('');
            menu3panel.style.display = 'block';
        } else {
            menu3panel.style.display = 'none';
        }
        menuover = true;
    }
}

/**
 * What does this function do?
 * @param {*} e what is this parameter?
 */
function menu5go(e) {
    // let cartLength = Object.keys(cartData).length;
    // if (cartLength!==0) {
    toggleCart();
    // }
}

/**
 * What does this function do?
 */
function openSelects() {
    headerArrow.style.transform = 'rotate(-0deg)';
    header.className = 'collapsingHeaderOpen';
    videoContent.classList.remove('videoContentClose');
    videoContent.classList.add('videoContentOpen');
    // projectsWrapper.className = 'projectsWrapperOpen';
    headerInner.className = 'headerInnerOpen';
    featuredContent.className = 'featuredContentOpen';
    menuBar.className = 'menuBarOpen';
    menuBarInner.className = 'menuBarInnerOpen';
    cartField.classList.remove('cartFieldClose');
    cartField.classList.add('cartFieldOpen');
    selectsTrayOpen = true;
}

/**
 * What does this function do?
 * @param {*} id what is this parameter?
 * @param {*} isResult what is this parameter?
 */
function openVideoPlayer(id, isResult) {
    currIdOfSelectedVideo = id;

    if (isResult) {
        currTypeOfSelectedVideo = 'results';// 'selects','paylist'
    } else {
        if (selectsTabOn) { // 'selects','paylist'
            currTypeOfSelectedVideo = 'selects';
        } else { // 'selects','paylist'
            currTypeOfSelectedVideo = 'paylist';
        }
    }

    setVideoSourceFeed();

    for (let q in rowDataOfSelectedVideo) {
        if (rowDataOfSelectedVideo.hasOwnProperty(q)) {
            if (rowDataOfSelectedVideo[q].vimeoid===id) {
                currLocOfSelectedVideo = q;
            }
        }
    }

    if (linearData[id].data.inCart) {
        videoHeaderPlus.className = 'minus';
    } else {
        videoHeaderPlus.className = 'plus';
    }

    playVideoByID(id);
    videoPlayer.style.left = '0px';
    videoPlayer.style.opacity = '1';
    // currRowOfSelectedVideo = i;
    setPrevNextText();
}

/**
 * What does this function do?
 * @param {*} vimID what is this parameter?
 */
function playVideoByID(vimID) {
    currIdOfSelectedVideo = vimID;
    // let videoEmbed = gid('videoEmbed');

    /* if(player===undefined){
        let voptions = {
            id: currIdOfSelectedVideo,
            autoplay:1,
            loop:0,
            muted:0
        };
        player = new Vimeo.Player(videoEmbed, voptions);
    }else{*/
    player.loadVideo(currIdOfSelectedVideo).then(function(id) {
        videoEmbed.style.visibility = 'visible';
        centerVideoText();
        player.play();
    }).catch(function(error) {
        switch (error.name) {
        case 'TypeError':
            // the id was not a number
            console.log('the id was not a number');
            break;

        case 'PasswordError':
            console.log('the video is password-protected and the viewer needs to enter the');
            // password first
            break;

        case 'PrivacyError':
            // the video is password-protected or private
            console.log('the video is password-protected or private');
            break;

        default:
            // some other error occurred
            console.log('some other error occurred');
            break;
        }
    });

    videoPlayerOverlay.style.top = '-100vh';

    player.on('pause', function(data) {
        if ((data.duration===0)) {
            videoPlayerOverlay.style.top = '-100vh';
        } else {
            videoPlayerOverlay.style.top = '0px';
        }
    });

    player.on('play', function(data) {
        videoPlayerOverlay.style.top = '-100vh';
        setPrevNextText();
    });

    player.on('ended', function(data) {
        videoPlayerOverlay.style.top = '0px';
        setPrevNextText();
    });
}

/**
 * What does this function do?
 */
/*
function preAddToPlaylist() {
    addCartPressed = false;
    delayedCall(0.3, function() {
        addCartPressed = true;
    });
}
*/

/**
 * What does this function do?
 * @param {*} rowSelectsItem what is this parameter?
 * @param {*} v what is this parameter?
 */
function positionMobileSelects(rowSelectsItem, v) {
    let slider = flickityWrapper.querySelector('.flickity-slider');
    if ((rowSelectsItem.id !== undefined) && (slider !== null)) {
        let bgRow = gid('rowBgInner');
        let eWidth = rowSelectsItem.offsetWidth;
        let eLeft = rowSelectsItem.getBoundingClientRect().left;
        let rowTitles = slider.childNodes[v].querySelector('.flickityInner');
        let rowAdd = slider.childNodes[v].querySelector('.flickityAddCart');
        let bgImg = bgRow.childNodes[v].querySelector('.rowSelectsItemBgImg');
        if (eLeft < -eWidth) {
            bgImg.style.display = 'none';
            // rowTxt.style.display = 'none';
            rowTitles.style.display = 'none';
            rowAdd.style.display = 'none';
        } else if (eLeft > eWidth) {
            bgImg.style.display = 'none';
            // rowTxt.style.display = 'none';
            rowTitles.style.display = 'none';
            rowAdd.style.display = 'none';
        } else {
            let opac = (1/(eWidth/15)*eLeft);
            // let per = -(50+(40/eWidth*eLeft));
            let per2 = -(50+(40/eWidth*eLeft));
            // let per3 = 50-(50/eWidth*eLeft);
            if (opac < 0) {
                opac = -opac;
            }
            bgImg.style.display = 'block';
            // rowTxt.style.display = 'block';
            rowTitles.style.display = 'block';
            rowAdd.style.display = 'block';
            // bgImg.style.backgroundPosition = per3+'% center';
            // rowTxt.style.transform = 'translateX('+per+'%)';
            rowTitles.style.transform = 'translateX('+per2+'%)';
            // rowTxt.style.opacity = 1-opac;
            rowTitles.style.opacity = 1-opac;
            rowAdd.style.opacity = 1-opac;
        }
    }
}
/**
 * What does this function do?
 * @param {*} vimID what is this parameter>
 */
function primeVideoplayer(vimID) {
    let videoEmbed = gid('videoEmbed');
    videoEmbed.style.visibility = 'hidden';

    let voptions = {
        id: vimID,
        autoplay: 0,
        loop: 0,
        muted: 0,
        title: 0,
        byline: 0,
        color: 'B59343',
    };
    player = new Vimeo.Player(videoEmbed, voptions);
}

/**
 * What does this function do?
 */
function resetAndSetSelectsRow() {
    let feat = gid('featuredContent');
    let pWrap = gid('projectWrapper');
    let fWrap = gid('mainCarousel');
    feat.removeChild(pWrap);
    feat.removeChild(fWrap);
    counter = 0;
    flickityObj.destroy();

    setSelectsRow();
    console.log('init Flick');
    initFlickity();
}

/**
 * What does this function do? "RESET RESULTS"
 */
function resetResults() {
    resultsArray = [];

    for (let z in pArray) {// populate results array with a copy of the datasheet
        if (pArray.hasOwnProperty(z)) {
            resultsArray.push(pArray[z]);
        }
    }
}

/**
 * What does this function do?
 */
function resetSearchField() {
    resetResults();
    content.removeChild(contentWrapper);
    sortArray(resultsArray, 'pageTitle', false);
    setResults();
    search.value = 'SEARCH';
}

/**
 * What does this function do? "SEARCH SUGGESTION"
 * @param {*} e what is this parameter?
 */
function searchSuggestion(e) {
    if ((e.keyCode || e.which)=== 13) {
        e.preventDefault();
    }
    let s1 = search.value;
    findItemsWith(s1, 'partial');
}

/**
 * What does this function do?
 */
function setBrands() {
    let tmp = [];
    for (let i in resultsArray) {// through the pages
        if (resultsArray[i].pageTitle.length > 0) {
            setUniqueArray(resultsArray[i].pageTitle, tmp);
        }
    }

    let atozItem = document.createElement('div');
    menu3panelScroll.appendChild(atozItem);
    atozItem.className = 'dropDownMenuItem';
    atozItem.innerHTML = 'A to Z';
    atozItem.addEventListener('click', function(e) {
        menu1go(false);
        closeEverythingElse('SEARCH');
    });

    let mobileAtozItem = document.createElement('div');
    menu3panelScroll.appendChild(mobileAtozItem);
    mobileAtozItem.className = 'dropDownMenuItem';
    mobileAtozItem.innerHTML = 'A to Z';
    mobileAtozItem.addEventListener('click', function(e) {
        menu1go(false);
        closeEverythingElse('SEARCH');
    });

    let ztoaItem = document.createElement('div');
    menu3panelScroll.appendChild(ztoaItem);
    ztoaItem.className = 'dropDownMenuItem';
    ztoaItem.innerHTML = 'Z to A';
    ztoaItem.addEventListener('click', function(e) {
        menu1go(true);
        closeEverythingElse('SEARCH');
    });

    let mobileZtoaItem = document.createElement('div');
    menu3panelScroll.appendChild(mobileZtoaItem);
    mobileZtoaItem.className = 'dropDownMenuItem';
    mobileZtoaItem.innerHTML = 'Z to A';
    mobileZtoaItem.addEventListener('click', function(e) {
        menu1go(true);
        closeEverythingElse('SEARCH');
    });

    for (let h in tmp) {
        if (tmp.hasOwnProperty(h)) {
            let menuItem = document.createElement('div');
            menu3panelScroll.appendChild(menuItem);
            menuItem.className = 'dropDownMenuItem';
            menuItem.innerHTML = tmp[h].split('Z_').join('');
            menuItem.addEventListener('click', function(e) {
                findItemsWith(tmp[h], 'exact');
                closeEverythingElse(tmp[h]);
            });
            let mobileMenuItem = document.createElement('div');
            mobileMenu3panelScroll.appendChild(mobileMenuItem);
            mobileMenuItem.className = 'dropDownMenuItem';
            mobileMenuItem.innerHTML = tmp[h].split('Z_').join('');
            mobileMenuItem.addEventListener('click', function(e) {
                findItemsWith(tmp[h], 'exact');
                closeEverythingElse(tmp[h]);
            });
        }
    }
}

/**
 * What does this function do?
 */
function setCartIcons() {
    updateCartIndicators();
    buildCart();
    setPlaylistArray();

    let tmp = [];
    for (let r in pArray) {
        if (pArray.hasOwnProperty(r)) {
            for (let s in pArray[r].page) {
                if (pArray[r].page.hasOwnProperty(s)) {
                    let data = pArray[r].page[s];
                    if (data.inCart) {
                        tmp.push(data.vimeoid);
                    }
                }
            }
        }
    }

    if (!selectsTabOn) {
        resetAndSetSelectsRow();
    }

    let carts = Array.from(content.querySelectorAll('.rowItemAddCart'));
    carts.forEach(div => {
        let rowItemAddCart = div;
        let id = rowItemAddCart.parentNode.id;
        let carted = false;
        for (let i in tmp) {
            if (tmp[i]===id) {
                carted = true;
                break;
            }
        }
        if (carted) {
            rowItemAddCart.className = 'rowItemAddCart minusgold';
        } else {
            rowItemAddCart.className = 'rowItemAddCart plusgold';
        }
    });

    let scarts = Array.from(featuredContent.querySelectorAll('.rowItemSelectsAddCart'));
    scarts.forEach(div => {
        let rowItemAddCart = div;
        let id = rowItemAddCart.parentNode.id;
        let carted = false;
        for (let i in tmp) {
            if (tmp[i]===id) {
                carted = true;
                break;
            }
        }
        if (carted) {
            rowItemAddCart.className = 'rowItemSelectsAddCart minusSelectsgold';
        } else {
            rowItemAddCart.className = 'rowItemSelectsAddCart plusSelectsgold';
        }
    });

    let fcarts = Array.from(flickityWrapper.querySelectorAll('.flickityAddCart'));
    fcarts.forEach(div => {
        let flickAddCart = div;
        let id = flickAddCart.parentNode.parentNode.id;
        let carted = false;
        for (let i in tmp) {
            if (tmp[i]===id) {
                carted = true;
                break;
            }
        }
        if (carted) {
            flickAddCart.className = 'flickityAddCart minusSelectsgold';
        } else {
            flickAddCart.className = 'flickityAddCart plusSelectsgold';
        }
    });
}

/**
 * What does this function do?
 */
function setCategories() {
    let tmp = [];
    for (let i in resultsArray) {// through the pages
        if (resultsArray.hasOwnProperty(i)) {
            for (let j in resultsArray[i].page) {// through page items
                if (resultsArray[i].page.hasOwnProperty(j)) {
                    if (resultsArray[i].page[j].maincategory.length > 0) {
                        setUniqueArray(resultsArray[i].page[j].maincategory, tmp);
                    }
                }
            }
        }
    }

    tmp.sort();
    for (let h in tmp) {
        if (tmp.hasOwnProperty(h)) {
            let menuItem = document.createElement('div');
            menu2panelScroll.appendChild(menuItem);
            menuItem.className = 'dropDownMenuItem';
            menuItem.innerHTML = tmp[h];
            menuItem.addEventListener('click', function(e) {
                findItemsWith(tmp[h], 'exact');
                closeEverythingElse(tmp[h]);
            });

            let mobileMenuItem = document.createElement('div');
            mobileMenu2panelScroll.appendChild(mobileMenuItem);
            mobileMenuItem.className = 'dropDownMenuItem';
            mobileMenuItem.innerHTML = tmp[h];
            mobileMenuItem.addEventListener('click', function(e) {
                findItemsWith(tmp[h], 'exact');
                closeEverythingElse(tmp[h]);
            });
        }
    }
}

/**
 * What does this function do?
 * @param {*} n what is this parameter?
 * @return {*} what does this return?
 */
function setColumns(n) {
    let key = n.toString();
    // app:edited _links title vimeoid featured show description thumbnail privacy specialproject archive maincategory year notes distribution tags sectionNumber brand thumbnailPath timeStamp duration _xml id
    if (key==='title' || key==='vimeoid' || key==='year' || key==='maincategory' || key==='notes' || key==='brand' || key==='description' || key==='tags') {
        return true;
    } else {
        return false;
    }
}

/**
 * What does this function do? "SET EVENTS"
 */
function setEvents() {
    clearSearch.addEventListener('click', resetSearchField);
    // clearSearch.addEventListener('tap', resetSearchField);
    window.addEventListener('scroll', function() {
        scrollY = window.pageYOffset;
        if (mobileSize) {
            let bounds = featuredContent.getBoundingClientRect();
            if (bounds.bottom <= 0) {
                menuBar.className = 'menuBarClose';
                header.className = 'collapsingHeaderClose';
                // mobileStickyHeader = true;
            } else {
                menuBar.className = 'menuBarOpen';
                header.className = 'collapsingHeaderOpen';
                // mobileStickyHeader = false;
            }
        } else {
            if ((selectsTrayOpen)&&(scrollY > 0)) {
                closeSelects();
            } else if ((!selectsTrayOpen)&&(scrollY <= 0)) {
                openSelects();
            }
        }
    });
    /*
    headerMobileToggle.addEventListener('click', function(){

    });
    */
    mobileMenu2Close.addEventListener('click', function() {
        mobileMenu2panel.style.top = '100vh';
    });
    mobileMenu3Close.addEventListener('click', function() {
        mobileMenu3panel.style.top = '100vh';
    });
    headerInner.addEventListener('click', function() {
        if (mobileSize) {
            // add toggle code here
            if (selectsTabOn) {
                selectsTabOn = false;
                headerMobileToggle.style.backgroundImage = 'url("../img/m_togglePill2.png")';
            } else {
                selectsTabOn = true;
                headerMobileToggle.style.backgroundImage = 'url("../img/m_togglePill.png")';
            }
            resetAndSetSelectsRow();
        } else {
            if (selectsTrayOpen) {
                selectsTrayOpen = false;
                closeSelects();
            } else {
                selectsTrayOpen = true;
                openSelects();
            }
        }
    });


    menuClick2.addEventListener('click', menu2go);
    menuClick22.addEventListener('click', menu2go);
    menuClick3.addEventListener('click', menu3go);
    menuClick33.addEventListener('click', menu3go);

    menuClick5.addEventListener('click', menu5go);

    menu2panel.addEventListener('mouseover', function() {
        menuover = true;
    });

    menu3panel.addEventListener('mouseover', function() {
        menuover = true;
    });
    /*
    menu4panel.addEventListener('mouseover',function(){
        menuover = true;
    });
    */
    menu2panel.addEventListener('mouseleave', function() {
        closeAfterTimeout();
        menuover = false;
    });

    menu3panel.addEventListener('mouseleave', function() {
        closeAfterTimeout();
        menuover = false;
    });

    cartHamburgerMenu.addEventListener('mouseleave', function() {
        closeAfterTimeout();
        menuover = false;
    });
    /*
    menu4panel.addEventListener('mouseleave',function(){
        //closeAfterTimeout();
        menuover = false;
    });
    */

    cartTrash.addEventListener('click', function() {
        cartData = {};
        for (let r in pArray) {
            if (pArray.hasOwnProperty(r)) {
                for (let s in pArray[r].page) {
                    if (pArray[r].page.hasOwnProperty(s)) {
                        let data = pArray[r].page[s];
                        data.inCart = false;
                    }
                }
            }
        }
        setCartIcons();
    });

    cartHamburger.addEventListener('click', function() {
        if (cartMenuOpen) {
            cartMenuOpen = false;
            cartHamburgerMenu.style.display = 'none';
        } else {
            cartMenuOpen = true;
            cartHamburgerMenu.style.display = 'block';
        }
    });
    // GENERATE PDF
    cartPdf.addEventListener('click', function() {
        promptPDFDownload();
    });

    /**
     * What does this function do?
     */
    function promptPDFDownload() {
        buildCartDataString(',');
        downloadPDFPrompt.style.display = 'block';
        downloadPDFPrompt.style.opacity = 1;
    }

    /**
     * What does this function do?
     * @param {*} separator what is this parameter?
     * @return {*} what does this return?
     */
    function buildCartDataString(separator) {// creates a string of data from the cart. the separator is determined by the type of string needed
        let cartDataString = '';

        for (let n in cartData) {
            if (cartData.hasOwnProperty(n)) {
                let str = '';
                if (parseInt(n)+1 === cartData.length) {
                    separator = '';
                }
                let hex = parseInt(currIdOfSelectedVideo).toString(16);
                str += 'BRAND: '+cartData[n].data.brand+' ';
                str += 'TITLE: '+cartData[n].data.title+' ';
                str += 'DESC: '+cartData[n].data.description+' ';
                str += 'CATEGORY: '+cartData[n].data.maincategory+' ';
                str += 'URL: '+window.location.protocol+'//'+window.location.host+'/?playlist='+hex+'&inplayer=1';
                str += separator;
                cartDataString += str;
            }
        }

        return cartDataString;
    }

    downloadPdfBtn.addEventListener('click', function() {
        goPDFDownload();
        downloadPDFPrompt.style.display = 'none';
        downloadPDFPrompt.style.opacity = 0;
        downloadPdfBtn.style.opacity = 0.3;
        downloadPdfBtn.style.cursor = 'no-drop';
    });

    /**
     * What does this function do?
     */
    function goPDFDownload() {
        window.location = 'pdf2/';
    }

    downloadPdfBtn.addEventListener('mouseover', function() {
        downloadPdfBtnImg.style.backgroundPosition = '-20px';
        downloadPdfBtnTxt.style.color = '#b59343';
        downloadPdfBtn.style.border = 'solid 1px #b59343';
    });

    downloadPdfBtn.addEventListener('mouseout', function() {
        downloadPdfBtnImg.style.backgroundPosition = '0px';
        downloadPdfBtnTxt.style.color = '#000000';
        downloadPdfBtn.style.border = 'solid 1px #000000';
    });
    // END GENERATE PDF
    // OTHER CART STUFF
    cartCopy.addEventListener('click', function() {
        cartCopyGo();
    });

    /**
     * What does this function do?
     */
    function cartCopyGo() {
        const cartDataString = buildCartDataString('\n\r');
        clipBoard.innerHTML = cartDataString;
        clipBoard.select();
        try {
            let successful = document.execCommand('copy');
            console.log(successful);
            cartCopyNotification.style.cssText += 'opacity:1; -webkit-transition: opacity 0.25s; -moz-transition: opacity 0.25s; -o-transition: opacity 0.25s; transition: opacity 0.25s;';
            delayedCall(0.5, function() {
                cartCopyNotification.style.cssText += 'opacity:0; -webkit-transition: opacity 1s; -moz-transition: opacity 1s; -o-transition: opacity 1s; transition: opacity 1s;';
            });
            // let msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {/* ignore */}
    }

    cartShare.addEventListener('click', function() {
        cartShareGo();
    });

    /**
     * What does this function do?
     */
    function cartShareGo() {
        const cartDataString = buildCartDataString('\n\r');
        window.location.href = 'mailto:?subject=From the Video Directory...&body='+escape(cartDataString);
    }

    // END OTHER CART STUFF
}// END SET EVENTS

/**
 * What does this function do? "SET LINEAR DATA"
 */
function setLinearData() {
    for (let i in pArray) {
        if (pArray.hasOwnProperty(i)) {
            sortArray(pArray[i].page, 'title', false);// set sort elsewhere

            for (let j in pArray[i].page) {
                if (pArray[i].page.hasOwnProperty(j)) {
                    let d = pArray[i].page[j];
                    linearData[pArray[i].page[j].vimeoid] = {'data': d};
                }
            }
        }
    }
}

/**
 * What does this function do?
 */
function setPlaylistArray() {
    playListArray = [];
    for (let r in pArray) {
        if (pArray.hasOwnProperty(r)) {
            for (let s in pArray[r].page) {
                if (pArray[r].page.hasOwnProperty(s)) {
                    let data = pArray[r].page[s];
                    if (data.inCart) {
                        playListArray.push(data);
                    }
                }
            }
        }
    }
}

/**
 * What does this function do?
 */
function setPrevNextText() {
    let j = currLocOfSelectedVideo;

    if (rowDataOfSelectedVideo.length < 2) {
        videoPlayerHide.style.display = 'none';
    } else {
        videoPlayerHide.style.display = 'block';
        let prevNum = parseInt(j)-1;
        let nextNum = parseInt(j)+1;

        if (prevNum < 0) {
            prevNum = parseInt(rowDataOfSelectedVideo.length-1);
        }
        if (nextNum > parseInt(rowDataOfSelectedVideo.length-1)) {
            nextNum = 0;
        }
        videoPlayerPrevBrand.innerHTML = rowDataOfSelectedVideo[prevNum].brand;
        videoPlayerNextBrand.innerHTML = rowDataOfSelectedVideo[nextNum].brand;
        videoPlayerPrevTitle.innerHTML = rowDataOfSelectedVideo[prevNum].title;
        videoPlayerNextTitle.innerHTML = rowDataOfSelectedVideo[nextNum].title;
        videoPlayerPrevCategory.innerHTML = rowDataOfSelectedVideo[prevNum].maincategory;
        videoPlayerNextCategory.innerHTML = rowDataOfSelectedVideo[nextNum].maincategory;
    }

    videoPlayerBrand.innerHTML = rowDataOfSelectedVideo[j].brand.split('Z_').join('');
    videoPlayerTitle.innerHTML = rowDataOfSelectedVideo[j].title;
    videoPlayerCategoryMobile.innerHTML = rowDataOfSelectedVideo[j].maincategory;
    videoPlayerCategoryDesktop.innerHTML = rowDataOfSelectedVideo[j].maincategory;
    videoPlayerDescription.innerHTML = rowDataOfSelectedVideo[j].description;
}

/**
 * What does this function do?
 */
function setResults() {
    contentWrapper = document.createElement('div');
    contentWrapper.className = 'contentWrapper';
    content.appendChild(contentWrapper);

    for (let i in resultsArray) {
        if (resultsArray.hasOwnProperty(i)) {
            // sortArray(resultsArray[i].page,'title',false);//set sort elsewhere
            let atLeastOneActive = false;
            let atLeastOneNotShown = false;
            let buttonPlaced = false;

            for (let j in resultsArray[i].page) {
                if (resultsArray[i].page.hasOwnProperty(j)) {
                    if ((resultsArray[i].page[j].archive.toString() === 'FALSE') || (resultsArray[i].page[j].archive.toString().length === 0)) {// determine if row has any archive columns that aren't set to "TRUE"
                        atLeastOneActive = true;
                    }
                    if (resultsArray[i].page[j].show.toString() === 'FALSE') {
                        atLeastOneNotShown = true;
                    }// use this to determine if there are hidden videos
                }
            }

            if (atLeastOneActive) {// build row if at least one video is not archived
                let rowObj = buildRow(true, contentWrapper, i);

                rowObj.rowHeadTitle.innerHTML = resultsArray[i].pageTitle.split('Z_').join('');

                let j = 0;

                if (atLeastOneNotShown) {
                    sortArray(resultsArray[i].page, 'show', true);
                }

                for (j in resultsArray[i].page) {
                    if ((resultsArray[i].page[j].archive.toString() === 'FALSE') || (resultsArray[i].page[j].archive.toString().length === 0)) {// If the archive column isn't set to "TRUE", display item
                        let bgImg = '';
                        if (resultsArray[i].page[j].thumbnail.length > 0) {
                            bgImg = '../img/thumbnails/'+resultsArray[i].page[j].thumbnail;
                        } else {
                            if (resultsArray[i].page[j].thumbnailPath!==undefined) {
                                bgImg = resultsArray[i].page[j].thumbnailPath;
                            } else {
                                bgImg = '../img/thumbnails/courageous.png';
                            }
                        }

                        let rowItem = document.createElement('div');
                        if (resultsArray[i].page[j].show.toString() === 'FALSE') {
                            rowItem.className = 'rowItem hideItem';
                            if (!buttonPlaced) {
                                buttonPlaced = true;
                                buildShowHideButton(rowObj.rowInner, i);
                            }
                        } else {
                            rowItem.className = 'rowItem';
                        }
                        rowObj.rowInner.appendChild(rowItem);
                        rowItem.id = resultsArray[i].page[j].vimeoid;
                        resultsArray[i].page[j].thumbnailPath = bgImg;
                        /*
                        let rowItemBgImg = document.createElement('div');
                        rowItemBgImg.className = 'rowItemBgImg';
                        rowItem.appendChild(rowItemBgImg);
                        rowItemBgImg.style.backgroundImage = 'url('+bgImg+')';
                        */
                        let rowItemImg = document.createElement('img');
                        rowItemImg.className = 'rowItemImg';
                        rowItem.appendChild(rowItemImg);
                        rowItemImg.src = bgImg;
                        if (!initialImageLoad) {
                            imageToLoadCount++;
                            rowItemImg.onload = thumbloaded;
                        }

                        buildItem(true, rowItem, i, j);
                    }
                }
                addScrollingWithVelocity(rowObj.rowBody, rowObj.rowInner, rowObj.scrollArrowRight, rowObj.scrollArrowLeft, true);
            }
        }
    }
}

/**
 * What does this function do? "SET SELECTS ROW"
 */
function setSelectsRow() {
    // desktop
    console.log('selects row');
    projectWrapper = document.createElement('div');
    projectWrapper.id = 'projectWrapper';
    featuredContent.appendChild(projectWrapper);
    let rowObj = buildRow(false, projectWrapper, undefined);
    // set up flickity main container
    flickityWrapper = document.createElement('div');
    flickityWrapper.id = 'mainCarousel';
    flickityWrapper.className = 'main-carousel';
    featuredContent.appendChild(flickityWrapper);
    rowObj.rowHeadTitle.innerHTML = 'SELECTS';
    rowHeadSelectsTitle = rowObj.rowHeadTitle;

    rowObj.rowHeadPlaylistTitle = document.createElement('div');
    rowObj.rowHeadPlaylistTitle.id = 'rowHeadPlaylistTitle';
    rowObj.rowHead.appendChild(rowObj.rowHeadPlaylistTitle);
    rowObj.rowHeadPlaylistTitle.innerHTML = 'PLAYLIST';
    rowHeadPlaylistTitle = rowObj.rowHeadPlaylistTitle;

    rowObj.rowHeadTitle.addEventListener('click', function() {
        if (selectsTrayOpen) {
            if (selectsTabOn) {
                closeSelects();
            } else {
                if (isItemInCart) {
                    selectsTabOn = true;
                    resetAndSetSelectsRow();
                }
            }
        } else {
            if (!selectsTabOn) {
                selectsTabOn = true;
            }
            resetAndSetSelectsRow();
            openSelects();
        }
    });


    rowObj.rowHeadPlaylistTitle.addEventListener('click', function() {
        if (selectsTrayOpen) {
            if (!selectsTabOn) {
                closeSelects();
            } else {
                if (isItemInCart) {
                    selectsTabOn = false;
                    resetAndSetSelectsRow();
                }
            }
        } else {
            if (selectsTabOn) {
                selectsTabOn = false;
            }
            resetAndSetSelectsRow();
            openSelects();
        }
    });

    setTabs();
    for (let m in pArray) {
        if (pArray.hasOwnProperty(m)) {
            for (let n in pArray[m].page) {
                if (pArray[m].page.hasOwnProperty(n)) {
                    let featuredBoolean = (pArray[m].page[n].featured === 'TRUE');
                    if (!selectsTabOn) {
                        featuredBoolean = (pArray[m].page[n].inCart === true);
                    }

                    if (featuredBoolean) {
                        let bgImg = '';
                        if (pArray[m].page[n].thumbnail.length > 0) {
                            bgImg = '../img/thumbnails/'+pArray[m].page[n].thumbnail;
                        } else {
                            if (pArray[m].page[n].thumbnailPath!==undefined) {
                                bgImg = pArray[m].page[n].thumbnailPath;
                            } else {
                                bgImg = '../img/thumbnails/courageous.png';
                            }
                        }

                        featuredArray.push(pArray[m].page[n]);

                        let rowBgItem = document.createElement('div');
                        rowBgItem.className = 'rowSelectsBgItem';
                        rowObj.rowBgInner.appendChild(rowBgItem);

                        let rowItemBgImg = document.createElement('div');
                        rowItemBgImg.className = 'rowSelectsItemBgImg';
                        rowBgItem.appendChild(rowItemBgImg);
                        rowItemBgImg.style.backgroundImage = 'url('+bgImg+')';
                        rowItemBgImg.id = 'rowItemBgImg'+counter;

                        let rowItem = document.createElement('div');
                        rowItem.className = 'rowSelectsItem';
                        rowObj.rowInner.appendChild(rowItem);
                        rowItem.id = pArray[m].page[n].vimeoid;

                        let rowItemImgWrapper = document.createElement('div');
                        rowItemImgWrapper.className = 'rowSelectsItemImgWrapper';
                        rowItem.appendChild(rowItemImgWrapper);

                        let rowItemImg = document.createElement('img');
                        rowItemImg.className = 'rowSelectsItemImg';
                        rowItemImgWrapper.appendChild(rowItemImg);
                        rowItemImg.src = bgImg;
                        rowItemImg.addEventListener('click', function() {
                            openVideoPlayer(rowItem.id, false);
                        });
                        /*
                        let rowItemDescription = document.createElement('div');
                        rowItemDescription.className = 'rowSelectsItemDescription';
                        rowItemImgWrapper.appendChild(rowItemDescription);
                        rowItemDescription.innerHTML = pArray[m].page[n].description;
                        */
                        pArray[m].page[n].thumbnailPath = bgImg;

                        if (!initialImageLoad) {
                            imageToLoadCount++;
                            rowItemImg.onload = thumbloaded;
                        }

                        buildItem(false, rowItem, m, n);
                        // build flickity slides
                        let rowItemFlickity = document.createElement('div');
                        rowItemFlickity.className = 'carousel-cell';// rowSelectsItem
                        flickityWrapper.appendChild(rowItemFlickity);
                        rowItemFlickity.id = pArray[m].page[n].vimeoid;

                        let flickityImgWrapper = document.createElement('div');
                        flickityImgWrapper.className = 'flickityImgWrapper';// rowSelectsItemImgWrapper
                        rowItemFlickity.appendChild(flickityImgWrapper);

                        let flickityImg = document.createElement('img');
                        flickityImg.className = 'flickityImg';// rowSelectsItemImg
                        flickityImgWrapper.appendChild(flickityImg);
                        flickityImg.src = bgImg;

                        let flickityPlayImg = document.createElement('div');
                        flickityPlayImg.className = 'flickityPlayImg';// rowSelectsItemImg
                        flickityImgWrapper.appendChild(flickityPlayImg);
                        flickityPlayImg.src = bgImg;
                        flickityPlayImg.addEventListener('click', function() {
                            openVideoPlayer(rowItemFlickity.id, false);
                        });

                        let flickityInner = document.createElement('div');
                        flickityInner.id = 'flickityInner'+m;
                        flickityInner.className = 'flickityInner';// rowItemSelectsInner
                        rowItemFlickity.appendChild(flickityInner);
                        flickityInner.addEventListener('click', function(e) {
                            if (isClickThrough) {
                                openVideoPlayer(rowItemFlickity.id, false);
                            }
                        });
                        let flickityBrandText = document.createElement('div');
                        flickityInner.appendChild(flickityBrandText);
                        let str = pArray[m].pageTitle.split('Z_').join('');// currArray[i].page[j].title;
                        flickityBrandText.className = 'flickityBrandText';// rowItemSelectsText
                        if (str !== undefined) {
                            flickityBrandText.innerHTML = truncate(str, 30); // cap at 60 charaters
                        }

                        let flickityTitle = document.createElement('div');
                        flickityInner.appendChild(flickityTitle);
                        flickityTitle.className = 'flickityTitle'; // rowItemSelectsTitle
                        let tstr = pArray[m].page[n].title;
                        if (tstr !== undefined) {
                            // tstr = tstr.toUpperCase();
                            flickityTitle.innerHTML = truncate(tstr, 60); // cap at 60 charaters
                        }

                        let flickityCategory = document.createElement('div');
                        flickityInner.appendChild(flickityCategory);
                        flickityCategory.className = 'flickityCategory';
                        let cat = pArray[m].page[n].maincategory;
                        if (cat !== undefined) {
                            // cat = cat.toUpperCase();
                            flickityCategory.innerHTML = truncate(cat, 60); // cap at 60 charaters
                        }

                        let flickityAddCart = document.createElement('div');
                        if (pArray[m].page[n].inCart===false) {
                            flickityAddCart.className = 'flickityAddCart plusSelectsgold';
                        }
                        if (pArray[m].page[n].inCart===true) {
                            flickityAddCart.className = 'flickityAddCart minusSelectsgold';
                        }
                        flickityImgWrapper.appendChild(flickityAddCart);
                        flickityAddCart.addEventListener('click', function(e) {
                            currIdOfSelectedVideo = rowItemFlickity.id;
                            if ((e.target.className.indexOf('plusgold')!==-1)||(e.target.className.indexOf('plusSelectsgold')!==-1)) {
                                addToPlaylist();
                            } else {
                                deleteFromCart();
                            }
                        });

                        /*
                        let flickityDescription = document.createElement('div');
                        flickityDescription.className = 'flickityDescription';// rowSelectsItemDescription
                        flickityImgWrapper.appendChild(flickityDescription);
                        flickityDescription.innerHTML = pArray[m].page[n].description;
                        */
                        counter++;
                    }
                }
            }
        }
    }
    addScrollingWithVelocity(rowObj.rowBody, rowObj.rowInner, rowObj.scrollArrowRight, rowObj.scrollArrowLeft, false);
}

/**
 * What does this function do?
 */
function setTabs() {// make sure correct tabs are showing under specific conditions
    if (isItemInCart) {
        rowHeadPlaylistTitle.style.display = 'block';
        rowHeadPlaylistTitle.style.cursor = 'pointer';
        rowHeadSelectsTitle.style.cursor = 'pointer';

        if (selectsTabOn) {
            rowHeadPlaylistTitle.style.color = '#D8D8D8';
            rowHeadSelectsTitle.style.color = '#000000';
        } else {
            rowHeadPlaylistTitle.style.color = '#000000';
            rowHeadSelectsTitle.style.color = '#D8D8D8';
        }
    } else {
        rowHeadSelectsTitle.style.color = '#000000';
        rowHeadPlaylistTitle.style.color = '#000000';

        rowHeadPlaylistTitle.style.display = 'none';
        rowHeadPlaylistTitle.style.cursor = 'auto';
        rowHeadSelectsTitle.style.cursor = 'auto';
    }
}

/**
 * What does this function do?
 * @param {*} o what is this parameter?
 * @param {*} arr what is this parameter?
 */
function setUniqueArray(o, arr) {
    let match = false;
    for (let h in arr) {
        if (o === arr[h]) {
            match = true;
            break;
        }
    }
    if (!match) {
        arr.push(o);
    }
}

/**
 * What does this function do?
 */
function setVideoPlayerAdd() {
    if (linearData[currIdOfSelectedVideo].data.inCart) {
        videoHeaderPlus.className = 'plus';
        deleteFromCart();
    } else {
        videoHeaderPlus.className = 'minus';
        addToPlaylist();
    }

    if (currTypeOfSelectedVideo==='paylist') {
        setVideoSourceFeed();
    }// only update playlist if viewing
}

/**
 * What does this function do?
 */
function setVideoPlayerVim() {
    window.open('https://vimeo.com/'+currIdOfSelectedVideo);
    player.pause();
}

/**
 * What does this function do?
 */
function setVideoSourceFeed() {
    if (currTypeOfSelectedVideo==='selects') { // use the featured videos array to navigate through
        rowDataOfSelectedVideo = featuredArray;
    } else if (currTypeOfSelectedVideo==='paylist') { // use the featured videos array to navigate through
        rowDataOfSelectedVideo = playListArray;
    } else { // find the whole section the selected video is from
        rowDataOfSelectedVideo = pArray[linearData[currIdOfSelectedVideo].data.sectionNumber].page;
    }
}

/**
 * What does this function do?
 */
function shareVideo() {
    let hex = parseInt(currIdOfSelectedVideo).toString(16);
    clipBoard.innerHTML = window.location.protocol+'//'+window.location.host+'/?playlist='+hex+'&inplayer=1';
    clipBoard.select();
    let msg = '';
    try {
        let successful = document.execCommand('copy');
        msg = successful ? 'successful' : 'unsuccessful';
        videoHeaderNotification.style.cssText += 'opacity:1; -webkit-transition: opacity 0.25s; -moz-transition: opacity 0.25s; -o-transition: opacity 0.25s; transition: opacity 0.25s;';
        delayedCall(0.5, function() {
            videoHeaderNotification.style.cssText += 'opacity:0; -webkit-transition: opacity 1s; -moz-transition: opacity 1s; -o-transition: opacity 1s; transition: opacity 1s;';
        });
    } catch (err) {
        console.log(msg);
    }
}

/**
 * What does this function do?
 * @param {*} arr what is this parameter?
 * @param {*} key what is this parameter?
 * @param {*} rev what is this parameter?
 */
function sortArray(arr, key, rev) {
    const cmp = rev ? -1 : 1;
    arr.sort((a, b) => a[key] > b[key] ? cmp : -cmp);
}

/**
 * What does this function do?
 */
function thumbloaded() {
    imageLoadCount++;
    if (imageLoadCount===imageToLoadCount) {
        initialImageLoad = true;
    }
}

/**
 * What does this function do?
 */
function toggleCart() {
    if (!cartOpen) {
        cartOpen = true;
        // videoContent.style.width = '75%';
        videoContent.classList.remove('videoContentCartHidden');
        videoContent.classList.add('videoContentCartVisible');
        cartField.classList.remove('cartHidden');
        cartField.classList.add('cartVisible');
        /*
        if(mobileSize){
            delayedCall(1,function(){
                if(cartOpen){
                    projectsWrapper.className = 'projectsWrapperClose';
                    //projectsWrapper.style.height = '100vh';
                    //projectsWrapper.style.overflowY = 'hidden';
                }
            });
            //cartField.style.position = 'relative';
            //cartField.style.position = 'fixed';
            //cartField.style.top = '130px';
        }*/
    } else {
        cartOpen = false;
        // videoContent.style.width = '100%';
        videoContent.classList.remove('videoContentCartVisible');
        videoContent.classList.add('videoContentCartHidden');
        cartField.classList.remove('cartVisible');
        cartField.classList.add('cartHidden');
        cartHamburgerMenu.style.display = 'none';
        cartMenuOpen = false;
        /* if(mobileSize){
            projectsWrapper.className = 'projectsWrapperOpen';
            //projectsWrapper.style.height = 'auto';
            //projectsWrapper.style.overflowY = 'visible';
            //cartField.style.position = 'absolute';
            //cartField.style.top = 'calc(100vh + 130px)';
        }*/
    }
    delayedCall(1, checkForOverlap);
}

/**
 * What does this function do?
 * @param {*} n what is this parameter?
 * @param {*} rowItem what is this parameter?
 * @param {*} rowItemText what is this parameter?
 */
function toggleHiddenItems(n, rowItem, rowItemText) {
    let thisRow = gid('rowInner'+n);
    for (let i in thisRow.childNodes) {
        if (thisRow.childNodes[i].className === 'rowItem hideItem') {
            thisRow.childNodes[i].className = 'rowItem showItem';
            typeInText(rowItemText, 'HIDE EXTENDED<br />ASSETS');
            rowItem.className = 'row-show-hide grey';
        } else if (thisRow.childNodes[i].className === 'rowItem showItem') {
            thisRow.childNodes[i].className = 'rowItem hideItem';
            typeInText(rowItemText, 'MORE EXTENDED<br />ASSETS');
            rowItem.className = 'row-show-hide black';
        }
    }
    checkForOverlap();
}

/**
 * What does this function do?
 * @param {*} str what is this parameter?
 * @param {*} n what is this parameter?
 * @return {*} what does this return?
 */
function truncate(str, n) {
    if (str.length > n) {
        let cut = str.slice(0, n-1);
        let lastSpace = cut.lastIndexOf(' ');
        let cut2 = cut.slice(0, lastSpace);
        str = cut2+'...';
    }
    return str;
}

/**
 * What does this function do?
 * @param {*} e what is this parameter?
 * @param {*} s what is this parameter?
 */
function typeInText(e, s) {
    let length = s.length;
    let inc = 0;
    let typed = '';

    let tickType = function() {
        typed = s.substr(0, inc);
        if (inc < length) {
            window.requestAnimationFrame(tickType);
            inc++;
        }
        e.innerHTML = typed;
    };
    window.requestAnimationFrame(tickType);
}

/**
 * What does this function do? "UPDATE CART"
 */
function updateCartIndicators() {
    let cartLength = Object.keys(cartData).length;
    cartNumber.innerHTML = cartLength;
    headerMobileTogglePlaylistNumber.innerHTML = cartLength;

    if (cartLength > 0) {
        cartNumberBg.style.display = 'block';
        isItemInCart = true;
        headerMobileToggle.style.display = 'block';
    } else {
        cartNumberBg.style.display = 'none';
        isItemInCart = false;
        headerMobileToggle.style.display = 'none';
    }
    if (cartLength===0) {
        cartOpen = false;
        /*
        videoContent.classList.remove('videoContentCartVisible');
        videoContent.classList.add('videoContentCartHidden');
        cartField.classList.remove('cartVisible');
        cartField.classList.add('cartHidden');
        */
        selectsTabOn = true;
        resetAndSetSelectsRow();
    } else {
        // cartField.classList.remove('cartHidden');
        // cartField.classList.add('cartVisible');
    }

    setTabs();
}

/**
 * What does this function do?
 */
function videoPlayerNextBtnClick() {
    currLocOfSelectedVideo++;
    if (currLocOfSelectedVideo > rowDataOfSelectedVideo.length-1) {
        currLocOfSelectedVideo = 0;
    }
    playVideoByID(rowDataOfSelectedVideo[currLocOfSelectedVideo].vimeoid);
    // setPrevNextText();
}

/**
 * What does this function do?
 */
function videoPlayerPrevBtnClick() {
    currLocOfSelectedVideo--;
    if (currLocOfSelectedVideo < 0) {
        currLocOfSelectedVideo = rowDataOfSelectedVideo.length-1;
    }
    playVideoByID(rowDataOfSelectedVideo[currLocOfSelectedVideo].vimeoid);
    // setPrevNextText();
}

const clipBoard = gid('clipBoard');
const header = gid('collapsingHeader');

const projectsWrapper = gid('projectsWrapper');
const content = gid('projectsContent');
// const playlist = gid('playlistContent');
const directoryBody = gid('directoryBody');
const headerInner = gid('headerInner');
const videoContent = gid('videoContent');
const cartField = gid('cartField');
const cartContent = gid('cartContent');
const videoPlayer = gid('videoPlayer');
const videoplayerBack = gid('videoplayerBack');
const videoplayerBack2 = gid('videoplayerBack2');
// const videoElement = gid('videoElement');
const videoPlayerHit = gid('videoPlayerHit');
const videoPlayerOverlay = gid('videoPlayerOverlay');
const videoPlayerPrevBtn = gid('videoPlayerPrevBtnHolder');
const videoPlayerNextBtn = gid('videoPlayerNextBtnHolder');
const videoPlayerPrevBrand = gid('videoPlayerPrevBrand');
const videoPlayerNextBrand = gid('videoPlayerNextBrand');
const videoPlayerPrevTitle = gid('videoPlayerPrevTitle');
const videoPlayerNextTitle = gid('videoPlayerNextTitle');
const videoPlayerPrevCategory = gid('videoPlayerPrevCategory');
const videoPlayerNextCategory = gid('videoPlayerNextCategory');
const videoHeaderNotification = gid('videoHeaderNotification');
const videoPlayerText = gid('videoPlayerText');
const videoHeaderShare = gid('videoHeaderShare');
const videoHeaderPlus = gid('videoHeaderPlus');
const videoPlayerBrand = gid('videoPlayerBrand');
const videoPlayerTitle = gid('videoPlayerTitle');
const videoPlayerCategoryMobile = gid('videoPlayerCategoryMobile');
const videoPlayerCategoryDesktop = gid('videoPlayerCategoryDesktop');
const videoPlayerDescription = gid('videoPlayerDescription');
// const videoplayerDownload = gid('videoplayerDownload');
const headerMobileToggle = gid('headerMobileToggle');
const menuBar = gid('menuBar');
const menuBarInner = gid('menuBarInner');
/*
const menu1 = gid('menu1');
const menu2 = gid('menu2');
const menu3 = gid('menu3');
const menu4 = gid('menu4');
const menu5 = gid('menu5');
*/
const menuClick2 = gid('menuClick2');
const menuClick22 = gid('menuClick22');
const menuClick3 = gid('menuClick3');
const menuClick33 = gid('menuClick33');
// const menuClick4 = gid('menuClick4');
const menuClick5 = gid('menuClick5');
const clearSearch = gid('clearSearch');
const menu2panel = gid('menu2panel');
const menu2panelScroll = gid('menu2panelScroll');
const menu3panel = gid('menu3panel');
const menu3panelScroll = gid('menu3panelScroll');

const mobileMenu2panel = gid('mobileMenu2panel');
const mobileMenu2panelScroll = gid('mobileMenu2panelScroll');
const mobileMenu3panel = gid('mobileMenu3panel');
const mobileMenu3panelScroll = gid('mobileMenu3panelScroll');
const mobileMenu2Close = gid('mobileMenu2Close');
const mobileMenu3Close = gid('mobileMenu3Close');

const cartHamburgerMenu = gid('cartHamburgerMenu');
const cartPdf = gid('cartPdf');
const cartCopy = gid('cartCopy');
const cartShare = gid('cartShare');
// const cartReplace = gid('cartReplace');

const search = gid('searchFilter');
const headerArrow = gid('headerArrowWrapper');
const headerMobileTogglePlaylistNumber = gid('headerMobileTogglePlaylistNumber');
let mobileSize = false;
// let mobileStickyHeader = false;
let scrollY = 0;
// let lastScrollY = 0;
// let currW = window.innerWidth;
// let currH = window.innerHeight;

let pArray = [];
let resultsArray = [];// sorted array to appear in a list
let featuredArray = [];// list of featured videos
let playListArray = [];// list of playlist videos
let urlArray = [];// holder for URL string data
// let videoArray = [];// list of all video IDs
let cartData = {};// user selected featurd videos
let linearData = {};// puts every video and its data into a liner object where each key is the vimeo ID. eliminates looping
let rowDataOfSelectedVideo = {};
let currLocOfSelectedVideo = 0;
// let currRowOfSelectedVideo = 0;
let currIdOfSelectedVideo = '';
let currTypeOfSelectedVideo = '';// 'results','selects','paylist'
// let menuOpen = false;
let cartOpen = false;
let cartMenuOpen = false;
let isClickThrough = true;
let isItemInCart = false;
let selectsTabOn = true;
let playerOnly = false;
// let searching = false;
let contentWrapper;
let projectWrapper;
let flickityWrapper;
let counter = 0;
let selectsTrayOpen = true;
// let playlistWrapper;
let rowHeadPlaylistTitle;
let rowHeadSelectsTitle;
let cartInner;
let cartTrash = gid('cartTrash');
let cartHamburger = gid('cartHamburger');
let cartNumber = gid('cartNumber');
let cartNumberBg = gid('cartNumberBg');
let cartCopyNotification = gid('cartCopyNotification');
// let addCartPressed = false;
let menuover = false;// for lingering menus when mouse leaves menu
// let selectsTitle = 'SELECTS';
let player = undefined;
let imageLoadCount = 0;
let imageToLoadCount = 0;
let initialImageLoad = false;
const downloadPDFPrompt = gid('downloadPDFPrompt');
const downloadPdfBtn = downloadPDFPrompt.querySelector('.download-pdf-btn');
const downloadPdfBtnTxt = downloadPDFPrompt.querySelector('.download-pdf-btn-txt');
const downloadPdfBtnImg = downloadPDFPrompt.querySelector('.download-pdf-btn-img');
let menu2YOpen = false;
let menu3YOpen = false;
let flickityObj;
let flickityCurrLoc = 0;
let flickityLastLoc = undefined;

/*
const cancelBtn = downloadPDFPrompt.querySelector('.download-pdf-cancel');
const cancelBtnTxt = downloadPDFPrompt.querySelector('.download-pdf-cancel-txt');
const cancelBtnImg = downloadPDFPrompt.querySelector('.download-pdf-cancel-img');
*/
/* BB: Explicitly declaring implicit variables */
const featuredContent = document.querySelector('#featuredContent');
// const flickityContent = document.querySelector('#flickityContent');
const videoPlayerHide = document.querySelector('#videoPlayerHide');
const videoEmbed = document.querySelector('#videoEmbed');
const videoHeaderVim = gid('videoHeaderVim');
let isTouchDevice = 'ontouchstart' in document.documentElement;
// gid('test').style.display = 'none';

(function main() {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.addEventListener('load', function() {
        // pArray = JSON.parse(xhr.response);
        /*
        for (let i in xhr.response) {
            if ()
            pArray.push(xhr.response[i]);
        }
        */
        pArray = xhr.response.slice();
        init();
    });
    // xhr.open('GET', 'gData.json');
    xhr.open('GET', /localhost/.test(location.href) ? 'https://cvd-old.courageousstudios.com/app.json' : 'app.json');
    xhr.send();

    videoplayerBack.addEventListener('click', closeVideoPlayer);
    videoplayerBack2.addEventListener('click', closeVideoPlayer);
    videoHeaderShare.addEventListener('click', shareVideo);
    videoHeaderPlus.addEventListener('click', setVideoPlayerAdd);
    videoHeaderVim.addEventListener('click', setVideoPlayerVim);
    videoPlayerHit.addEventListener('click', closeOverlay);
    videoPlayerPrevBtn.addEventListener('click', videoPlayerPrevBtnClick);
    videoPlayerNextBtn.addEventListener('click', videoPlayerNextBtnClick);

    window.addEventListener('resize', function() {
        insetDots();
        checkRotation();
        centerVideoText();
        checkForOverlap();
        checkWidth();
    });
}());

/**
 * What does this function do?
 * @param {*} str what is this parameter?
 * @param {*} n what is this parameter?
 * @return {*} what does this return?

function iOS() {
    let iDevices = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
    ];

    if (!navigator.platform) {
        while (iDevices.length) {
            if (navigator.platform === iDevices.pop()) {
                return true;
            }
        }
    }
    return false;
}
 */
